import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectMenu({ label, resolution, options, callback }) {
   const handleChange = (event) => {
      callback(event.target.value);
   };

   return (
      <Box sx={{ width: 300 }}>
         <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
               value={resolution}
               label={label}
               onChange={handleChange}
               defaultValue={options[1]}
            >
               {options.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
               ))}
            </Select>
         </FormControl>
      </Box>
   );
}
