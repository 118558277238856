import React, { useState, useEffect, useRef } from "react";

import Downloader from "./Downloader";
import logo from "./images/pdlogo.png";

import { SketchPicker } from 'react-color';
import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import Patterns from "./Patterns";
import PatternMenu from "./PatternMenu";
import Slider from "./Slider";
import Switch from "./Switch";
import SelectMenu from "./SelectMenu";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import Popover from "@mui/material/Popover";

import ScaleToWidth from "./ScaleToWidth";

export default function App() {
   const [testPattern, setTestPattern] = React.useState("");

   const [circles, setCircles] = useState(false);
   const [text, setText] = useState(false);
   const [luma, setLuma] = useState(false);
   const [grid, setGrid] = useState(false);

   const [gridSize, setGridSize] = React.useState(1);
   const [bkgndColor, setBkgndColor] = React.useState('#333333')

   const [resolution, setResolution] = React.useState("1920x1080");
   const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
   const [colorPickerAnchor, setColorPickerAnchor] = React.useState(null);

   const patternRef = useRef(null);

   const handleColorChange = (newValue) => {
      setBkgndColor(newValue.hex)
    }

   return (
      <Box sx={{ p: 4 }}>
         <Stack
            direction='row'
            spacing={4}
            justifyContent='flex-start'
            alignItems='center'
            sx={{
               mb: 2,
               p: 4,
               borderRadius: 1,
               backgroundColor: "rgba(255,255,255,.9)",
            }}
         >
            <img src={logo} width={120} />

            <PatternMenu pattern={testPattern} callback={setTestPattern} />

            {testPattern && (
               <Downloader
                  enabled={true}
                  resolution={resolution}
                  downloadName={"TestPattern_" + testPattern}
               >
                  <Patterns
                     pattern={testPattern}
                     gridSize={gridSize}
                     circles={circles}
                     luma={luma}
                     text={text}
                     grid={grid}
                     bkgndColor={bkgndColor}
                  />
               </Downloader>
            )}
         </Stack>

         {testPattern == "grid" && (
            <Stack
               direction='row'
               spacing={4}
               justifyContent='flex-start'
               alignItems='center'
               sx={{
                  mb: 2,
                  px: 4,
                  py: 2,
                  borderRadius: 1,
                  backgroundColor: "rgba(255,255,255,.7)",
               }}
            >

            <SelectMenu
               label='Resolution'
               value={resolution}
               callback={setResolution}
               options={["1280x720","1920x1080","3840x2160"]}
            />
               
               <Switch label='Circles' value={circles} callback={setCircles} />
               <Switch label='Text' value={text} callback={setText} />

               <Stack direction='row' spacing={2} alignItems='center' pl={2}>
                  <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Background</Typography>
                  <RadioGroup
                     value={grid ? "grid" : luma ? "luma" : "none"}
                     onChange={(e) => {
                        const val = e.target.value;
                        setGrid(val === "grid");
                        setLuma(val === "luma");
                     }}
                     row
                  >
                     <FormControlLabel value="none" control={<Radio />} label="None" />
                     <FormControlLabel value="grid" control={<Radio />} label="Grid" />
                     <FormControlLabel value="luma" control={<Radio />} label="Luma" />
                  </RadioGroup>
               </Stack>

               {grid && (
                  <Slider label='Grid Size' value={gridSize} callback={setGridSize} />
               )}
               
               {!luma && (
                  <Stack direction='row' spacing={2} alignItems='center' pl={2}>
                     <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Background</Typography>
                     <Popover
                     open={colorPickerOpen}
                     anchorEl={colorPickerAnchor}
                     onClose={() => setColorPickerOpen(false)}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                     }}
                  >
                     <SketchPicker color={bkgndColor} onChange={handleColorChange} disableAlpha={true} />
                  </Popover>
                  <Button onClick={(e) => {
                     setColorPickerAnchor(e.currentTarget);
                     setColorPickerOpen(true);
                  }}>
                     <Box sx={{ width: 30, height: 30, backgroundColor: bkgndColor, border: '2px solid #333' }} />
                  </Button>
               </Stack>
               )}
            </Stack>
         )}

         <ScaleToWidth contentWidth={1920} contentHeight={1080}>
            {(parent) => (
               <Patterns
                  pattern={testPattern}
                  gridSize={gridSize}
                  circles={circles}
                  luma={luma}
                  text={text}
                  grid={grid}
                  bkgndColor={bkgndColor}
               />
            )}
         </ScaleToWidth>
      </Box>
   );
}
