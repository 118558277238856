import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import MuiInput from "@mui/material/Input";
import VolumeUp from "@mui/icons-material/VolumeUp";

const Input = styled(MuiInput)`
   width: 42px;
`;

export default function InputSlider({ label, value, callback }) {
   const handleSliderChange = (event, newValue) => {
      callback(newValue);
   };

   const handleInputChange = (event) => {
      callback(event.target.value === "" ? 0 : Number(event.target.value));
   };

   const handleBlur = () => {
      if (value < 0) {
         callback(0);
      } else if (value > 100) {
         callback(100);
      }
   };

   return (
      <Stack direction='row' spacing={2} alignItems='center'>
         <Typography id='input-slider' gutterBottom sx={{ fontSize: 16, fontWeight: 600 }}>
            {label}
         </Typography>
         <Slider
            value={typeof value === "number" ? value : 1}
            onChange={handleSliderChange}
            step={.01}
            min={1}
            max={10}
            valueLabelDisplay='auto'
            sx={{ width: 50 }}
         />
      </Stack>
   );
}
